body {
  color: white;
}

.App {
  text-align: center;
  background-image: linear-gradient(#8f2d47, #ff5e48);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 3vmin;
  min-height: 32px;
  pointer-events: none;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #721a28;
  padding-top: 2vh;
}

.App-link {
  color: #61dafb;
}
